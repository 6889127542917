import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHpProps } from 'src/hpProps';
import { NavLink, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Tabs } from '@veneer/core';
import { RmcMfeLoader } from '@jarvis/react-mfe-component';
import { useStoreState } from 'src/store/useStoreState';
export const DevicesHomepageMain = (props) => {
  const { stack, shell, navigation, customRelativePath, getBreadcrumb, removeBreadcrumb } =
    useHpProps();
  const baseurl = customRelativePath || '/';
  const baseName = navigation ? navigation.createHref({ pathname: baseurl }) : baseurl;
  const [currentTab, setCurrentTab] = useState<number | undefined>(getCurrentTab());
  const { homepageState } = useStoreState();
  const { devicesHomepageRefresh } = homepageState;
  let tabs = [];

  useEffect(() => {
    function tabHandler() {
      setCurrentTab(getCurrentTab());
    }
    window.addEventListener('popstate', tabHandler);
    if (
      getBreadcrumb().at(-1).text != 'Devices' &&
      getBreadcrumb().at(-1).text != 'HP Secure Fleet Manager: Devices'
    ) {
      removeBreadcrumb(getBreadcrumb().at(-1).key);
    }

    return () => window.removeEventListener('popstate', tabHandler);
  }, []);

  function getCurrentTab() {
    const pathArray = navigation.location.pathname.split('/');
    let tabPath = typeof pathArray[2] === 'undefined' ? '' : pathArray[2];

    switch (tabPath) {
      case `assigned`:
        return 0;
      case `unassigned`:
        return 1;
      default:
        return 0;
    }
  }

  const TabMfe = useCallback(
    ({ component, type }) => {
      return (
        <div style={{ padding: '16px 0px 0px 0px' }}>
          <RmcMfeLoader
            disableContainerPadding
            {...props}
            {...{
              component,
              type,
              stack,
              shell,
            }}
          />
        </div>
      );
    },
    [shell, stack, props],
  );

  tabs.push({
    id: 0,
    label: (
      <StyledNavLink exact to="/" activeClassName="selected" data-testid="assigned-tab">
        {'Assigned'}
      </StyledNavLink>
    ),
    title: 'Assigned Devices',
    description: '',
    content: (
      <Route path="">
        <TabMfe type="ECPAssignedDevices" component="@jarvis/react-ecp-devices" />
      </Route>
    ),
  });

  // if (CURRENT_STACK.LOCAL) {
  //   tabs.push({
  //     id: 1,
  //     label: (
  //       <StyledNavLink to="/unassigned" activeClassName="selected" data-testid="unassigned-tab">
  //         {'Unassigned'}
  //       </StyledNavLink>
  //     ),
  //     title: 'Unassigned Devices',
  //     description: '',
  //     content: (
  //       <Route path="(|/unassigned)">
  //         <TabMfe type="ECPUnassignedDevices" component="@jarvis/react-ecp-unassigned-devices" />
  //       </Route>
  //     ),
  //   });
  // }

  const memoRenderComponent = useMemo(() => {
    console.log('[devicesHomepage] render devices-home tab');

    return (
      <div data-testid="device-homepage-tab">
        <Router basename={baseName}>
          <Switch>
            {tabs.length !== 0 && (
              <StyledTabs
                controlId="extended"
                mode="extended"
                selectedTabId={currentTab}
                tabs={tabs}
                data-testid="devices-homepage-tabs"
              />
            )}
          </Switch>
        </Router>
      </div>
    );
  }, [shell, stack, currentTab, devicesHomepageRefresh]);

  return <>{memoRenderComponent}</>;
};

const StyledTabs = styled(Tabs)``;

const StyledNavLink = styled(NavLink)`
  color: black;
  text-decoration: none;

  &.selected {
    color: ${tokens.colorHpBlue6};
  }

  &:focus,
  &:hover,
  &:link,
  &:active,
  &:visited {
    text-decoration: none;
  }
`;
