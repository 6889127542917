import { store } from 'src/utils/constants';

export const storeStartRootComponent = (_payload) => {
  return {
    type: store.homepage.START_ROOT_COMPONENT,
    payload: _payload,
  };
};

export const storeDevicesHomepageRefresh = (_payload) => {
  return {
    type: store.homepage.DEVICES_HOMEPAGE_REFRESH,
    payload: _payload,
  };
};
