import 'src/styles/global.scss';
import React from 'react';
import { setProps } from 'src/utils/commonUtils';
import { useHpProps } from 'src/hpProps';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { getHomepageStoreActions } from 'src/store/homepage/init';
import { RmcCustomHook, RmcUtils } from '@jarvis/react-mfe-component';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { DevicesHomepageMainContainer } from './DevicesHomepageMainContainer';
import { MainTitle } from './DevicesHomepageMainContainer';
import SubMfeDeviceDetails from './SubMfeDeviceDetails';

export const RootComponent = (props) => {
  const { homepageState } = useStoreState();
  const { startRootComponent } = homepageState;
  const { navigation, deviceDetailsComponent, customRelativePath } = useHpProps();
  const baseurl = props.customRelativePath || '/devices';
  const baseName = navigation ? navigation.createHref({ pathname: baseurl }) : baseurl;
  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  // init all redux stores
  RmcCustomHook.useConstructor(() => {
    setProps(props);
    RmcUtils.commonMethods.setRmcProps(props);

    dispatchAll(getHomepageStoreActions());
  });

  const didReduxUpdated = () => {
    return startRootComponent;
  };

  const renderDeviceDetailsComponent = () => {
    return deviceDetailsComponent !== null ? (
      deviceDetailsComponent
    ) : (
      <SubMfeDeviceDetails {...props} {...{ customRelativePath }} />
    );
  };

  const renderRootComponent = () => {
    if (didReduxUpdated()) {
      return (
        <Router basename={baseName} data-testid="root-component">
          <Switch>
            <Route exact path="/(|devices|unassigned)">
              <MainTitle />
              <DevicesHomepageMainContainer {...props} />
            </Route>
            <Route path="/:devID">{renderDeviceDetailsComponent()}</Route>
          </Switch>
        </Router>
      );
    } else {
      return <></>;
    }
  };

  return renderRootComponent();
};
