import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHpProps } from 'src/hpProps';
import { useRefreshHook } from 'src/hooks/useRefreshHook';
import { RmcMfeLoader } from '@jarvis/react-mfe-component';

export const MainTitle = () => {
  const { showDevicesMainHeader, events, localization } = useHpProps();
  const { refreshPage } = useRefreshHook();

  const handleReloadPage = () => {
    refreshPage();
  };

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', handleReloadPage);
    return () => {
      events.removeEventListener('ecp-banner-reload-call', handleReloadPage);
    };
  }, [events, handleReloadPage]);

  const SubMfe = useCallback(({ component, title, disableReload, type }) => {
    return (
      <RmcMfeLoader
        disableContainerPadding
        {...{
          component,
          title,
          type,
          localization,
        }}
      />
    );
  }, []);

  const memoGlobalHeader = useMemo(() => {
    return (
      <div className="react-ecp-header-wrapper">
        <SubMfe type="headerTitle" component="@jarvis/react-ecp-header" />
      </div>
    );
  }, []);

  return <>{showDevicesMainHeader && <Wrapper>{memoGlobalHeader}</Wrapper>}</>;
};

const Wrapper = styled.div`
  margin-bottom: 24px;
`;
