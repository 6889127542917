import { store } from 'src/utils/constants';

const initialState = {
  startRootComponent: false,
  devicesHomepageRefresh:true,
};

const homepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.homepage.START_ROOT_COMPONENT:
      return {
        ...state,
        startRootComponent: action.payload,
      };
    case store.homepage.DEVICES_HOMEPAGE_REFRESH:
      return {
        ...state,
        devicesHomepageRefresh: action.payload,
      };
    default:
      return state;
  }
};

export default homepageReducer;
