import React from 'react';
import HpProps from './hpProps';
import { Stack } from '@jarvis/web-stratus-client';

export const HpPropsProvider = (props) => {
  return (
    <HpProps.Provider
      value={{
        ecpDeviceV55: props.ecpDeviceV55,
        stack: props.stack ?? Stack.pie,
        shell: props.shell,
        localization: props.localization,
        events: props.events,
        useToast: props.useToast(),
        authProvider: props.authProvider,
        navigation: props.navigation,
        getBreadcrumb: props.getBreadcrumb,
        setBreadcrumb: props.setBreadcrumb,
        removeBreadcrumb: props.removeBreadcrumb,
        showDevicesMainHeader: props.showDevicesMainHeader,
        deviceDetailsMfe: props.deviceDetailsMfe,
        deviceDetailsComponent: props.deviceDetailsComponent,
        customRelativePath: props.customRelativePath,
      }}
    >
      {props.children}
    </HpProps.Provider>
  );
};
