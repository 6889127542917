export const dateFormat = {
  default: 'MMM DD, YYYY hh:mm:ss A', // March 17th, 2022 02:45:30 PM (12 hours format)
};

export const sessionStorageKey = {
  serviceID: 'serviceID',
};

export const store = {
  homepage: {
    START_ROOT_COMPONENT: 'homepage/startRootComponent',
    DEVICES_HOMEPAGE_REFRESH: 'homepage/devicesHomepageRefresh',
  },
};

export const service = {
  collection: 'collection',
  collectionStratusPie: 'collectionStratusPie',
  deviceCache: 'deviceCache',
  subscriptions: 'subscriptions',
  programMgtSvc: 'ProgramMgtSvc',
  accountMgtSvc: 'AccountMgtSvc',
};
