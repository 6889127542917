import { useDispatch } from 'react-redux';
import { storeDevicesHomepageRefresh } from 'src/store/homepage/action';

export const useRefreshHook = () => {
  const dispatch = useDispatch();

  const _refresh = (action) => {
    dispatch(action(false));
    setTimeout(() => dispatch(action(true)), 100);
  };

  const refreshPage = () => {
    _refresh(storeDevicesHomepageRefresh);
  };

  return {
    refreshPage,
  };
};
