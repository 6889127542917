import moment from 'moment';
import { JarvisAuthProvider } from '@jarvis/web-http';
import AssetsProviderFactory from 'src/assets/AssetsProviderFactory';

type ManageMfeProps = {
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
};

const localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
};

export const setProps = (props) => {
  localProps.authProvider = props.authProvider;
  localProps.language = props.localization.language;
  localProps.stack = props.stack;
  localProps.country = props.localization.country;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-devices-homepage.${subkey}`, args);
};

export const getCurrentDateTime = (format) => {
  return moment().format(format);
};

export const refined = (data: any) => {
  if (data === undefined) {
    return t('unable_to_load_data');
  } else if (data === null || data === '') {
    return '--';
  } else {
    return data;
  }
};

export const isMockUpMode = () => {
  return !!(
    process.env.NODE_ENV == 'development' && localStorage.getItem('enable-devices-mockup-data')
  );
};

export const getMockUpData = async (moduleName: string) => {
  return await import(`src/mock/${moduleName}.json`);
};
