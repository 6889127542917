import React, { useMemo, useState } from 'react';
import { DevicesHomepageMain } from './DevicesHomepageMain';
import { HpLoadingSection } from 'src/components/Common/HpLoadingSection';
export const DevicesHomepageMainContainer = (props) => {
  const [isFetching, setIsFetching] = useState(false);

  const enableCondition = !isFetching;

  const memoRenderComponent = useMemo(() => {
    console.log('[devicesHomepage] render <DevicesHomepageMain />');

    if (enableCondition) {
      return <DevicesHomepageMain {...props} />;
    } else {
      return <></>;
    }
  }, [enableCondition]);

  return <>{isFetching ? <HpLoadingSection /> : <>{memoRenderComponent}</>}</>;
};
